import LoginButton from "../buttons/LoginButton";
import Image from "next/image";
import Link from "next/link";

const LandingHeader = () => {
  return (
    <div className="header header-container ">
      <div className="wrapper-90 header__content">
        <div className="header__logo">
          <Link href="https://thebohomarket.com">
            <a>
              <Image
                src="https://res.cloudinary.com/dkceblkn7/image/upload/v1705610983/boho%20app%20assets/logo/gtbh1n6td7xxah9i0mb7.svg"
                alt="logo"
                height="55.17"
                width="211.03"
                on
              />
            </a>
          </Link>
        </div>
        <div className="header__navbar">
          <LoginButton />
        </div>
      </div>
    </div>
  );
};

export default LandingHeader;
